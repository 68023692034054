import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Element } from 'react-scroll';
import NavBar from './Components/NavBar';
import Hero from './Components/Hero';
import './App.css';
import Services from './Components/Services/Services';
import WorkPage from './Components/Work';
import AboutMe from './Components/AboutUs/AboutMe';
import ContactMe from './Components/ContactUs';

function App() {
  return (
    <Router>
      <div className="App">
        <Element name="hero">
          <Hero />
        </Element>
        <Element name="services">
          <Services />
        </Element>
        <Element name="my_work">
          <WorkPage/>
        </Element>
        <Element name="about_us">
          <AboutMe/>
        </Element>
        <Element name="contact_us">
          <ContactMe/>
        </Element>
        <NavBar />
      </div>
    </Router>
  );
}

export default App;
