export default function WorkPage() {
  const projects = [
    {
      title: "AirConditioning Website",
      technologies: ["React", "Tailwind CSS", "SpringBoot", "MySQL"],
      image: "./assets/work/hima.webp", // Replace with your image URL
      link: "https://himaaircon.in", // Replace with the project link
    },
    {
      title: "E-Commerce Platform",
      technologies: ["React", "Tailwind CSS", "SpringBoot", "MySQL"],
      image: "./assets/work/ecom.webp", // Replace with your image URL
      link: "https://example.com/ecommerce", // Replace with the project link
    },
    {
      title: "Gym Website",
      technologies: ["React", "Tailwind CSS"],
      image: "./assets/work/arenafit.webp", // Replace with your image URL
      link: "https://example.com/socialmedia", // Replace with the project link
    },
  ];

  return (
    <div className="min-h-screen bg-[#1d1b16] text-[#e2d2ae] font-roboto">
      {/* Page Header */}
      <header className="py-16 bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] shadow-md">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-kugile tracking-wide text-[#e2d2ae]">
            My Projects
          </h1>
          <p className="mt-6 text-lg sm:text-xl text-[#c4b69c] max-w-3xl mx-auto leading-relaxed">
            A carefully curated selection of my work, showcasing the skills and technologies I bring to the table.
          </p>
        </div>
      </header>

      {/* Projects Grid */}
      <section className="container mx-auto px-6 sm:px-12 lg:px-24 py-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        {projects.map((project, index) => (
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            className="group rounded-lg shadow-lg overflow-hidden bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] hover:shadow-2xl hover:scale-[1.03] transform transition-all duration-300"
          >
            {/* Project Image */}
            <div className="relative overflow-hidden">
              <img
              loading="lazy"
                src={project.image}
                alt={project.title}
                className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-20 transition-opacity duration-300"></div>
            </div>

            {/* Project Details */}
            <div className="p-6">
              <h2 className="text-2xl font-kugile mb-4">{project.title}</h2>
              <p className="text-sm text-[#c4b69c] mb-4 leading-relaxed">
                <strong>Technologies:</strong> {project.technologies.join(", ")}
              </p>
              <button className="inline-block px-6 py-2 text-sm bg-gradient-to-r from-[#635343] to-[#4d3a29] text-[#e2d2ae] rounded-full hover:brightness-110 transition duration-300">
                View Project
              </button>
            </div>
          </a>
        ))}
      </section>
    </div>
  );
}
