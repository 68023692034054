import { Link } from "react-scroll";

export default function WebDevelopment() {
  return (
    <div className="relative w-full min-h-screen bg-[#1d1b16] text-[#e2d2ae]">
      {/* Background */}
      <div
        className="absolute inset-0 bg-cover bg-center animate-fadeIn"
        style={{
          backgroundImage: `url('/images/web-dev-background.jpg')`, // Replace with a relevant background image
        }}
      >
        <div className="absolute inset-0 bg-[#1d1b16] opacity-80"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 px-6 sm:px-12 md:px-16 lg:px-24 py-16 animate-slideInFromLeft">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-kugile leading-tight mb-4 text-[#e2d2ae] animate-slideUp">
            Web Development Services
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl font-light text-[#c4b69c] max-w-3xl mx-auto">
            I specialize in creating high-quality, fast, and responsive websites, as well as full-stack web applications. 
            My goal is to bring your digital ideas to life with modern, user-friendly solutions.
          </p>
        </div>

        {/* Service Details */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-12 px-6 sm:px-12 md:px-16">
          {/* Web Development */}
          <div className="bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] p-8 rounded-xl shadow-lg text-center hover:shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl animate-fadeInFromBottom">
            <h2 className="text-3xl font-kugile text-[#e2d2ae] mb-6">
              Web Development
            </h2>
            <p className="text-lg text-[#c4b69c] leading-relaxed">
              Whether you need a stunning landing page, a complex business site, or an interactive web application, I 
              specialize in building user-centric websites with a focus on performance, scalability, and security.
            </p>
          </div>

          {/* Full Stack Development */}
          <div className="bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] p-8 rounded-xl shadow-lg text-center hover:shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl animate-fadeInFromBottom">
            <h2 className="text-3xl font-kugile text-[#e2d2ae] mb-6">
              Full Stack Development
            </h2>
            <p className="text-lg text-[#c4b69c] leading-relaxed">
              From front-end to back-end, I provide end-to-end development services using the latest technologies. 
              I build scalable, efficient, and secure full-stack applications with modern frameworks such as React, Node.js, and Express.
            </p>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center">
          <p className="text-lg sm:text-xl md:text-2xl text-[#c4b69c] mb-6 leading-relaxed">
            Let's bring your vision to life with an expertly crafted web experience. Get in touch to discuss your project.
          </p>
          <Link
  to="contact_us" // The name of the section to scroll to
  smooth={true} // Enable smooth scrolling
  duration={500} // Set the duration of the scroll in milliseconds
  className="inline-block px-8 py-4 text-lg bg-gradient-to-r from-[#635343] to-[#4d3a29] text-[#e2d2ae] rounded-full shadow-lg hover:brightness-110 transform hover:scale-105 transition duration-300 cursor-pointer"
>
  Contact Me
</Link>
        </div>
      </div>
    </div>
  );
}
