import React from 'react';
import { FaMedal, FaUsers } from 'react-icons/fa';
import { GiShuttlecock } from "react-icons/gi";

const activities = [
  {
    title: "NCC 'C' Certificate",
    description:
      'Awarded the prestigious NCC C Certificate in 3(A) Naval Unit, Visakhapatnam during 2020-2023. This reflects my dedication, discipline, and leadership skills.',
    icon: <FaMedal />,
    timeline: '2020 - 2023',
  },
  {
    title: 'Sports Club Secretary',
    description:
      'Served as the Sports Club Secretary for the IT Department at Anil Neerukonda Institute of Technology and Sciences. Organized events and fostered a vibrant sports culture.',
    icon: <FaUsers />,
    timeline: '2023 - 2024',
  },
  {
    title: 'Badminton',
    description:
      'Passionate about badminton, often participating in the practice sessions and enjoying the thrill of the game.',
    icon: <GiShuttlecock />,
    timeline: 'Ongoing',
  },
];

export default function Extracurricular() {
  return (
    <div className="min-h-screen bg-[#1d1b16] text-[#e2d2ae] font-roboto">
      {/* Page Header */}
      <div className="py-12 bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] text-center shadow-lg">
        <h1 className="text-4xl sm:text-5xl font-kugile text-[#e2d2ae]">
          Extracurricular Activities
        </h1>
        <p className="mt-4 text-lg sm:text-xl text-[#c4b69c] font-light max-w-3xl mx-auto">
          Beyond academics—my achievements and passions that shape my well-rounded personality.
        </p>
      </div>

      {/* Activities Section */}
      <div className="container mx-auto px-6 sm:px-12 lg:px-24 py-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        {activities.map((activity, index) => (
          <div
            key={index}
            className="bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] rounded-xl shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl"
          >
            {/* Activity Icon */}
            <div className="p-6 flex items-center justify-center text-6xl text-[#e2d2ae]">
              {activity.icon}
            </div>

            {/* Activity Description */}
            <div className="p-6">
              <h2 className="text-2xl font-kugile mb-2 text-[#e2d2ae]">
                {activity.title}
              </h2>
              <p className="text-lg text-[#c4b69c] leading-relaxed mb-4">
                {activity.description}
              </p>
              <span className="block text-sm text-[#c4b69c] italic">
                {activity.timeline}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
