import React, { useState } from "react";
import { FaInstagram, FaTwitter } from "react-icons/fa";

export default function ContactMe() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true); // Show loading animation
    setFeedback(null); // Clear previous feedback

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      message: event.target.message.value,
    };

    try {
      const response = await fetch("http://localhost:5000/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to send the message.");
      }

      // Set success feedback
      setFeedback({ type: "success", message: "Your message has been sent successfully!" });

      // Reset form fields
      event.target.reset();
    } catch (error) {
      // Set error feedback
      setFeedback({ type: "error", message: error.message });
    } finally {
      setIsSubmitting(false); // Stop loading animation
    }
  };

  const closeModal = () => setFeedback(null);

  return (
    <div className="min-h-screen bg-[#1d1b16] text-[#e2d2ae] font-roboto flex flex-col items-center">
      {/* Contact Us Heading */}
      <h2 className="text-5xl font-kugile mt-12 mb-8 text-[#e2d2ae] text-center">
        Contact Me
      </h2>

      <div className="container mx-auto px-6 sm:px-12 lg:px-24 py-8 flex flex-col md:flex-row items-start gap-12">
        {/* Left Section */}
<div className="w-full md:w-1/2 flex flex-col items-start text-left mx-auto">
  <h3 className="text-4xl font-kugile mb-6 text-[#e2d2ae]">
    Get in Touch
  </h3>
  <div className="space-y-6 text-lg">
    {/* Email */}
    <div>
      <p className="font-medium text-lg">
        <strong>Email:</strong>
      </p>
      <a
        href="mailto:joey@chillreach.ai"
        className="hover:underline text-[#c4b69c] block mt-3"
      >
        joey@chillreach.ai
      </a>
    </div>

    {/* Phone */}
    <div>
      <p className="font-medium text-lg">
        <strong>Phone:</strong>
      </p>
      <a
        href="tel:+917901345269"
        className="hover:underline text-[#c4b69c] block mt-3"
      >
        +91 7901345269
      </a>
    </div>

    {/* Social Media */}
    <div>
      <p className="font-medium text-lg">
        <strong>Social Media:</strong>
      </p>
      <ul className="flex gap-6 mt-4">
        <li>
          <a
            href="https://instagram.com/thejoey.in"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#c4b69c] text-2xl hover:text-[#e2d2ae] transition mt-3"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/yourusername"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#c4b69c] text-2xl hover:text-[#e2d2ae] transition mt-3"
          >
            <FaTwitter />
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>



        {/* Right Section - Contact Form */}
        <div className="w-full md:w-1/2">
          <div className="bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] rounded-xl shadow-lg p-8 animate-fadeInFromBottom">
            
            <p className="text-lg sm:text-xl text-[#c4b69c] mb-8 text-center">
              Feel free to reach out to me for any inquiries or collaboration
              opportunities.
            </p>

            <form onSubmit={handleSubmit}>
              {/* Name Field */}
              <div className="mb-6">
                <label htmlFor="name" className="block text-[#e2d2ae] mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full p-3 rounded-lg bg-[#635343] text-[#e2d2ae] outline-none focus:ring-2 focus:ring-[#c4b69c]"
                  placeholder="Enter your name"
                  required
                />
              </div>

              {/* Email Field */}
              <div className="mb-6">
                <label htmlFor="email" className="block text-[#e2d2ae] mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full p-3 rounded-lg bg-[#635343] text-[#e2d2ae] outline-none focus:ring-2 focus:ring-[#c4b69c]"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Message Field */}
              <div className="mb-6">
                <label htmlFor="message" className="block text-[#e2d2ae] mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  className="w-full p-3 rounded-lg bg-[#635343] text-[#e2d2ae] outline-none focus:ring-2 focus:ring-[#c4b69c]"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>

              {/* Submit Button */}
              <div className="text-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-6 py-3 bg-gradient-to-r from-[#c4b69c] to-[#e2d2ae] text-[#1d1b16] rounded-full hover:brightness-110 hover:scale-105 transition duration-300 ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Loading Spinner */}
      {isSubmitting && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#e2d2ae]"></div>
        </div>
      )}

      {/* Feedback Modal */}
      {feedback && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#3e2c1b] text-[#e2d2ae] p-6 rounded-lg shadow-lg text-center w-96">
            <h3 className="text-3xl font-semibold mb-4 text-[#e2d2ae]">
              {feedback.type === "success" ? "🎉 Success!" : "⚠️ Error"}
            </h3>
            <p className="mb-6 text-lg">{feedback.message}</p>
            <button
              onClick={closeModal}
              className="px-4 py-2 bg-gradient-to-r from-[#c4b69c] to-[#e2d2ae] text-[#1d1b16] rounded-lg hover:scale-105 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
