import React from 'react'
import WebDevelopment from './WebDevelopement'

const Services = () => {
  return (
    <div>
      <WebDevelopment/>
    </div>
  )
}

export default Services
