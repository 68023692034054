import { Disclosure } from '@headlessui/react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-scroll'; // Import Link from react-scroll

const navigation = [
  { name: 'Home', section: 'hero' },
  { name: 'Services', section: 'services' },
  { name: 'Work', section: 'my_work' },
  { name: 'About Me', section: 'about_us' },
  { name: 'Contact Me', section: 'contact_us' },
];

export default function NavBar() {
  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-1/3 bg-dark-academia bg-opacity-80 rounded-full shadow-md flex justify-between items-center p-2 md:w-1/3 z-50">  <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between w-full md:hidden">
              <Disclosure.Button className="text-white focus:outline-none">
                {open ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
              </Disclosure.Button>
              <a
                href="https://wa.me/7901345269?text=Hello%20I%20have%20a%20question"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500"
              >
                <WhatsAppIcon fontSize="large" />
              </a>
            </div>

            {/* Full navigation for larger screens */}
            <div className="hidden md:flex justify-between w-full items-center">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.section} // Use 'to' to scroll to the section
                  smooth={true} // Enable smooth scroll
                  duration={500} // Set the scroll duration (optional)
                  className="text-white font-kugile hover:text-[#F5F0E6] p-2 cursor-pointer"
                >
                  {item.name}
                </Link>
              ))}
              <a
                href="https://wa.me/7901345269"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500"
              >
                <WhatsAppIcon fontSize="large" />
              </a>
            </div>

            {/* Mobile menu */}
            <Disclosure.Panel className="absolute bottom-16 left-1/2 transform -translate-x-1/2 bg-gray-800 bg-opacity-90 rounded-md shadow-lg w-full p-4 md:w-1/2">
              <div className="flex flex-col space-y-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.section}
                    smooth={true}
                    duration={500}
                    className="text-white hover:text-[#F5F0E6] p-2 text-left cursor-pointer"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
