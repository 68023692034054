export default function Hero() {
  return (
    <div className="overflow-hidden"> {/* Ensures no overflow */}
      <div
        className="relative w-full h-screen bg-gradient-to-br from-[#1d1b16] to-[#635343]"
        style={{
          backgroundImage: "url('./assets/bg2.webp')", // Replace with your background image URL
          backgroundSize: "cover",
          backgroundPosition: "center center", // Zooms the image by focusing on the center
          backgroundRepeat: "no-repeat",
          transform: "scale(1.1)", // Slight zoom effect
        }}
      >
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-[#1d1b16]/80 to-[#635343]/80"></div>

        <div className="relative flex flex-col md:flex-row items-center justify-between h-full px-4 sm:px-8 lg:px-16">
          {/* Left Section */}
          <div className="w-full mt-20 md:mt-4 md:w-1/2 text-center md:text-right space-y-4 md:space-y-6 md:ml-auto">
            <h1 className="text-5xl sm:text-6xl md:text-6xl lg:text-7xl font-slim text-[#e2d2ae] font-kugile leading-tight">
              Joseph Munnangi
            </h1>
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-[#d0c2a1] font-light">
              Portfolio
            </h2>
          </div>

          {/* Right Section */}
          <div className="w-full md:w-1/2 flex justify-center items-center mt-6 md:mt-0 mb-10">
            <img
              src="./assets/joeypp.webp" // Replace with your image URL
              alt="Joseph Munnangi"
              className="w-48 h-48 sm:w-64 sm:h-64 md:w-72 md:h-72 lg:w-96 lg:h-96 rounded-full border-4 border-[#c4b69c] shadow-xl object-cover hover:scale-105 transform transition-transform duration-300 ease-in-out"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
